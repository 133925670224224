<template>
  <div class="sign">
    <div class="search">
      <i class="jym-icon-test12"></i>
      <input type="text" v-model="keyword" placeholder="输入课程名称进行查找">
      <button @click="getList">搜索</button>
    </div>

    <div class="container">
      <ul>
        <li v-for="(item, index) in signList" :key="index">
          <div class="text">
            <p>{{ item.bookname }}</p>
            <p>{{ item.alias }}</p>
            <p class="small">共{{ item.make_num }}人，已签到{{ item.sign_num }}人， <span class="gold-num">未签到{{ item.make_num - item.sign_num }}人</span></p>
          </div>
          <div class="btn">
            <button @click="sign(item.id)">签到</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { lessonSign } from "@api/user";
export default {
  data() {
    return {
      keyword: '',
      page: 1,
      signList: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let that = this,
        params = {
          keyword: that.keyword,
          page: that.page,
          limit: 10
        };

      lessonSign(params).then(res => {
        that.signList = res.data
      })
      .catch(err => {
        that.$dialog.error(err.msg)
      })
    },

    // 签到跳转
    sign(id) {
      this.$router.push({
        path: `/user/siteCode/${id}`
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sign {
  .search {
    width: 100%;
    height: .8rem;
    background: white;
    position: relative;
    display: flex;
    justify-content: space-between;
    input {
      width: 5.7rem;
      margin: .1rem 0 .1rem .3rem ;
      height: .6rem;
      font-size: .24rem;
      border: .02rem solid #DDD;
      border-radius: .12rem;
      padding: 0 .65rem;
    }
    i.jym-icon-test12 {
      position: absolute;
      font-size: .36rem;
      top: .22rem;
      left: .5rem;
    }
    button {
      width: .9rem;
      height: .6rem;
      background: yellowgreen;
      margin: .1rem .3rem;
      font-size: .24rem;
      background: black;
      color: white;
      border-radius: .06rem;
    }
  }
  .container {
    margin-top: .2rem;
    ul li {
      margin-top: .1rem;
      width: 100%;
      background: white;
      font-size: .26rem;
      padding: .2rem .3rem;
      display: flex;
      justify-content: space-between;
      .text {
        width: 5.5rem;
        padding-right: .3rem;
        p {
          line-height: .4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 600;
        }
        p.small {
          font-weight: 500;
          font-size: .24rem;
        }
      }
      .btn {
        width: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
          color: white;
          width: 100%;
          height: .48rem;
          background: #26a2ff;
          text-align: center;
          line-height: .48rem;
        }
      }
    }
  }
}
</style>